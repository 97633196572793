#headerText{
  color: #FFFFFF;
  font-size: 2rem;
  text-align: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
#contentText{
    color: #FFFFFF;
    font-size: .8rem;
}
#contentDate{
    font-size: 1.5rem;
    padding-bottom: 0;
    color: #ff61ea;
    font-weight: 500;
}
#caroContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.caroTextBackground{
    background: rgba(0, 0, 0, .6);
}

.caroTextCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.caroContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  padding: 1vh 5vw 1vh 15%;

}
