#contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
#spotifyIcon{
  height: 2vh;
  width: 3vw;
  padding: 0 1vw;
}
h2{
  font-size: 2rem;
}
h3{
  font-size: 1.5rem;
  color: #61a3ff;
}
p {
  font-size: 1rem;
}
a{
  color: #61ff8e;
}
.contentContent {
  max-width: 90%;
  width: 90%;
  height: 35vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, .8);
  text-align: center;
  padding: 2vh 5vw;
  margin: 0 5vw;
  color: #FFFFFF;
}
.column {
  width: 100%;
  text-align: center;
}

/* Clear floats after the columns */
.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 5vw;
}
.contentImg {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  margin-right: 5vw;
}

.contentEntry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2vh;
}

.contentText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: left;
  padding-left: 5vw;
}
